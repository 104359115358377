@import '~@/assets/scss/main.scss';

.banner-logo-container {
    position: relative;

    .logo-circle {
        position: absolute;
        top: 10rem;
        height: 9rem;
        width: 9rem;
        border-radius: 9rem;
        border: 0.75rem solid white;
        background: white;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        @include material-shadow(1);
    }
}

.course-card-image {
    background-size: cover;
    background-color: v-bind(themeColorHex);
}

.card-title {
    margin-top: 4rem;
    font-size: 1.5rem;
    font-weight: bold;
}

.btn-bottom {
    border-radius: 0rem 0rem $border-radius-card $border-radius-card;
    color: white;
    background-color: v-bind(themeColorHex);
    transition: all $transition-duration;
    &:hover {
        filter: brightness(110%);
    }
}
.card-outer {
    position: relative;
    padding-bottom: 50px;
}
.card-actions {
    /* We're setting the flex classes here instead of using the bootstrap
    utility classes to have less repeated code*/
    position: absolute;
    bottom: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
